import React from "react"

import { RoadmapEntry as RoadmapEntryType } from "@/types/roadmap-entry"

import { cn } from "@/utils/tw-utils"
import { Image } from "@/components/ui/image"
import { StyledHtml } from "@/components/ui/styled-html"

type RoadmapProps = React.ComponentPropsWithoutRef<"ul"> & {
  roadmap: RoadmapEntryType[]
}

const Roadmap = React.forwardRef<HTMLUListElement, RoadmapProps>(
  ({ roadmap, className }: RoadmapProps, ref) => {
    return (
      <ul className={cn("flex flex-col md:gap-3", className)} ref={ref}>
        {roadmap.map((roadmapEntry, index) => (
          <RoadmapEntry key={index} index={index} roadmapEntry={roadmapEntry} />
        ))}
      </ul>
    )
  }
)
Roadmap.displayName = "Roadmap"

type RoadmapEntryProps = React.ComponentPropsWithoutRef<"div"> & {
  index: number
  roadmapEntry: RoadmapEntryType
}

const RoadmapEntry = ({
  roadmapEntry,
  index,
  className,
}: RoadmapEntryProps) => {
  const { title, description, subtitle, image } = roadmapEntry
  return (
    <li
      className={cn(
        "flex gap-4 border-b py-3 first:pt-0 last:border-b-0 md:gap-6 md:rounded-md md:border md:px-3 md:py-3 md:first:pt-3 md:last:border-b",
        className
      )}
    >
      <div>
        <div className="h-14 w-14 overflow-hidden rounded-full md:rounded-sm lg:h-24 lg:w-24">
          {image ? (
            <Image src={image} className="h-full w-full object-cover" />
          ) : (
            <div className="flex h-full w-full items-center justify-center bg-frozen/50 text-3xl text-primary backdrop-blur md:text-4xl">
              {index + 1}
            </div>
          )}
        </div>
      </div>
      <div>
        {subtitle ? (
          <div className="mb-1 text-sm font-normal uppercase leading-tight text-foreground/60">
            {subtitle}
          </div>
        ) : null}

        <h3 className="max-w-lg text-lg font-bold">{title}</h3>
        <StyledHtml html={description} className="mt-3 max-w-2xl text-sm" />
      </div>
    </li>
  )
}
RoadmapEntry.displayName = "RoadmapEntry"

export { Roadmap }
