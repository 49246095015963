import { useMemo } from "react"

import {
  LaunchpadCollection,
  LaunchpadCollectionStatus,
  LaunchpadCollectionWithDetails,
} from "@/types/launchpad-collection"

import { formatToCompactNotation } from "@/utils/number-utils"
import { cn } from "@/utils/tw-utils"
import { useLaunchStatus } from "@/hooks/use-launch-status"
import { useToast } from "@/hooks/use-toast"
import { useTotalSupplyRead } from "@/hooks/venom/use-total-supply-read"
import { Badge } from "@/components/ui/badge"
import { Countdown } from "@/components/ui/countdown"
import { Progress } from "@/components/ui/progress"
import { SeeMore } from "@/components/ui/see-more"
import { Skeleton } from "@/components/ui/skeleton"
import { VenomIcon } from "@/components/icons/venom-icon"
import { EndedIndicator } from "@/components/launchpad/ended-indicator"
import { LaunchMintingStages } from "@/components/launchpad/launch-miniting-stages"
import { LiveIndicator } from "@/components/launchpad/live-indicator"

type LaunchDetailsProps = {
  collection: LaunchpadCollectionWithDetails
}

const LaunchDetails = ({ collection }: LaunchDetailsProps) => {
  return (
    <div className="flex flex-col items-start gap-8">
      <LaunchDetailsHeader collection={collection} />

      {collection.description ? (
        <SeeMore
          text={collection.description}
          lineClamp={3}
          className="text-base"
        />
      ) : null}

      <LaunchMintingProgress collection={collection} className="w-full" />

      <div className="-mx-3 w-[calc(100%_+_1.5rem)] md:mx-0 md:w-full">
        <LaunchMintingStages className="w-full" collection={collection} />
      </div>
    </div>
  )
}
LaunchDetails.displayName = "LaunchDetails"

type LaunchMintingProgressProps = React.ComponentPropsWithoutRef<"div"> & {
  collection: LaunchpadCollection
}

const LaunchMintingProgress = ({
  collection,
  ...props
}: LaunchMintingProgressProps) => {
  const { toast } = useToast()
  const { status } = useLaunchStatus(collection)

  const isUnlimited =
    collection.maxSupplySum === undefined || collection.maxSupplySum === null

  const isDeployed = Boolean(collection.tvm.contractAddress)

  const formattedMaxSupply = isUnlimited
    ? "Unlimited"
    : formatToCompactNotation(collection.maxSupplySum!)

  const { totalSupply, isFetched } = useTotalSupplyRead(
    collection.tvm.contractAddress,
    {
      onError: (error) => {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: error.message,
        })
      },
    }
  )

  const progress = useMemo(() => {
    if (isUnlimited) {
      return 100
    }

    if (status === LaunchpadCollectionStatus.Ended) {
      return 100
    }

    if (!totalSupply) {
      return 0
    }

    return (totalSupply / collection.maxSupplySum!) * 100
  }, [status, totalSupply, collection.maxSupplySum, isUnlimited])

  const isLive = status === LaunchpadCollectionStatus.Live
  const isEnded = status === LaunchpadCollectionStatus.Ended
  const isPaused = status === LaunchpadCollectionStatus.Paused

  return isFetched || !isDeployed ? (
    <div {...props}>
      <div className="flex items-center justify-between text-base leading-tight">
        {status === LaunchpadCollectionStatus.Upcoming ? (
          <>
            <span className="font-normal">Available items</span>
            <span className="text-muted-foreground">{formattedMaxSupply}</span>
          </>
        ) : null}

        {isUnlimited ? (
          <>
            {isLive || isPaused ? (
              <>
                <span className="font-normal">Items minted</span>
                <span className="text-muted-foreground">
                  {totalSupply} / {formattedMaxSupply}
                </span>
              </>
            ) : null}
          </>
        ) : (
          <>
            {isLive || isPaused ? (
              <>
                <span className="font-normal">
                  {progress.toFixed(1)}% minted
                </span>
                <span className="text-muted-foreground">
                  {totalSupply} / {formattedMaxSupply}
                </span>
              </>
            ) : null}
          </>
        )}

        {isEnded ? (
          <>
            <span className="font-normal">Items minted</span>
            <span className="text-muted-foreground">{totalSupply}</span>
          </>
        ) : null}
      </div>

      <Progress value={progress} className="mt-3 w-full" />
    </div>
  ) : (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <Skeleton className="h-4 w-60 rounded-full" />
        <Skeleton className="h-4 w-12 rounded-full" />
      </div>
      <Skeleton className="mt-3.5 h-1.5 w-full rounded-full" />
    </div>
  )
}
LaunchMintingProgress.displayName = "LaunchMintingProgress"

type LaunchDetailsHeaderProps = {
  collection: LaunchpadCollectionWithDetails
} & React.ComponentPropsWithoutRef<"div">

const LaunchDetailsHeader = ({
  collection,
  className,
  ...props
}: LaunchDetailsHeaderProps) => {
  const { name, tvm } = collection

  const { status, startDate } = useLaunchStatus(collection)

  const isLive = status === LaunchpadCollectionStatus.Live
  const isEnded = status === LaunchpadCollectionStatus.Ended
  const isUpcoming = status === LaunchpadCollectionStatus.Upcoming

  return (
    <div className={cn("flex flex-col", className)} {...props}>
      <h1 className="font-display text-3xl text-secondary">{name}</h1>

      <div className="mt-3">
        <div className="flex items-center gap-4">
          {isLive && (
            <Badge variant="frozen" className="h-9 px-3 text-base">
              <LiveIndicator />
            </Badge>
          )}

          {isUpcoming && (
            <Badge
              variant="frozen"
              className="pointer-events-auto h-9 px-1 text-base"
            >
              <Countdown date={startDate} />
            </Badge>
          )}

          {isEnded && (
            <Badge variant="frozen" className="h-9 px-3 text-base">
              <EndedIndicator />
            </Badge>
          )}

          <div className="flex h-9 flex-nowrap items-center gap-2 text-base font-normal">
            <VenomIcon variant="solid" className="h-4 w-4 text-primary" />
            Venom
          </div>

          <div className="flex h-9 flex-nowrap items-center gap-2 text-base font-normal">
            {tvm.contractType}
          </div>
        </div>
      </div>
    </div>
  )
}
LaunchDetailsHeader.displayName = "LaunchDetailsHeader"

export { LaunchDetails, LaunchMintingProgress, LaunchDetailsHeader }
