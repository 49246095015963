import { cn } from "@/utils/tw-utils"
import { Input, InputProps } from "@/components/ui/input"

type AmountInputProps = Omit<InputProps, "type" | "onChange"> & {
  min: number
  max: number
  value: number
  onChange: (value: number) => void
}

const AmountInput = ({
  min = 1,
  max = 999,
  value,
  className,
  onChange,
  ...props
}: AmountInputProps) => {
  return (
    <div className="flex w-full items-center gap-2">
      {/* <Button
        variant="ghost"
        size="icon"
        type="button"
        disabled={value <= min}
        className="h-8 w-8 flex-none"
        onClick={() => {
          if (value > min) {
            onChange?.(value - 1)
          }
        }}
      >
        <MinusIcon className="h-4 w-4" />
        <span className="sr-only">Decrease</span>
      </Button> */}

      <Input
        type="number"
        value={value}
        min={min}
        max={max}
        className={cn(
          "text-center [appearance:textfield] disabled:opacity-100 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
          className
        )}
        onChange={(event) => {
          if (event.target.valueAsNumber < min) {
            onChange(min)
            return
          }

          if (event.target.valueAsNumber > max) {
            onChange(max)
            return
          }

          onChange(event.target.valueAsNumber)
        }}
        {...props}
        disabled
      />

      {/* <Button
        variant="ghost"
        size="icon"
        className="h-8 w-8 flex-none"
        type="button"
        disabled={value >= max}
        onClick={() => {
          if (value < max) {
            onChange?.(value + 1)
          }
        }}
      >
        <PlusIcon className="h-4 w-4" />
        <span className="sr-only">Increase</span>
      </Button> */}
    </div>
  )
}

AmountInput.displayName = "AmountInput"

export { AmountInput }
