import { cn } from "@/utils/tw-utils"

export const attributeTitleStyle =
  "text-sm font-normal uppercase text-foreground/60"

type AttributeProps = {
  name: string
  children?: React.ReactNode
} & React.ComponentPropsWithoutRef<"div">

const Attribute = ({ name, children, ...props }: AttributeProps) => {
  return (
    <div {...props}>
      <div className={cn("mb-2", attributeTitleStyle)}>{name}</div>
      <div>{children}</div>
    </div>
  )
}

Attribute.displayName = "Attribute"

export { Attribute }
