import { useCallback, useEffect, useState } from "react"
import { Autoplay, EffectCreative, Navigation } from "swiper/modules"
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react"
import { Swiper as SwiperType } from "swiper/types"

import { MediaSize, Media as MediaType } from "@/types/media"

import { cn } from "@/utils/tw-utils"
import { Media } from "@/components/ui/media"

const launchMediaGalleryStyle = "-mx-3 md:mx-0 rounded-md"

type LaunchMediaGalleryProps = {
  media: MediaType[]
} & SwiperProps

const LaunchMediaGallery = ({
  media,
  className,
  ...props
}: LaunchMediaGalleryProps) => {
  const [previewSwiper, setPreviewSwiper] = useState<SwiperType | null>(null)
  const [thumbnailsSwiper, setThumbnailsSwiper] = useState<SwiperType | null>(
    null
  )

  const [previewActiveIndex, setPreviewActiveIndex] = useState<number | null>(0)

  const currentPreviewMedia =
    previewActiveIndex !== null ? media?.[previewActiveIndex] ?? null : null

  const handleSwipeChange = useCallback(
    (swiper: SwiperType) => {
      setPreviewActiveIndex(swiper.realIndex)
    },
    [setPreviewActiveIndex]
  )

  const handleThumbnailClick = useCallback(
    (index: number) => {
      previewSwiper?.slideTo(index)
    },
    [previewSwiper]
  )

  useEffect(() => {
    if (!thumbnailsSwiper || thumbnailsSwiper.destroyed) {
      return
    }

    if (previewSwiper && thumbnailsSwiper && previewActiveIndex !== null) {
      const isLast = previewActiveIndex === media.length - 1
      thumbnailsSwiper?.slideTo(
        isLast ? previewActiveIndex : Math.max(previewActiveIndex - 1, 0)
      )
    }
  }, [previewSwiper, thumbnailsSwiper, media, previewActiveIndex])

  return (
    <div>
      {currentPreviewMedia && (
        <div className="absolute left-0 right-0 top-0 -z-10 h-[26rem] overflow-hidden">
          <div className="absolute left-1/2 top-8 -z-20 w-full max-w-screen-2xl -translate-x-1/2 animate-breath opacity-20 blur-3xl">
            <Media
              media={currentPreviewMedia}
              skeleton={false}
              size={MediaSize.Lg}
            />
          </div>

          <div className="relative h-[26rem] bg-gradient-to-t from-background to-transparent" />
        </div>
      )}

      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        modules={[EffectCreative, Navigation, Autoplay]}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: true,
        }}
        navigation
        effect="creative"
        onSlideChange={handleSwipeChange}
        onSwiper={setPreviewSwiper}
        className={cn(launchMediaGalleryStyle, className)}
        {...props}
      >
        {media?.map((m, index) => {
          return (
            <SwiperSlide key={index}>
              <LaunchMediaGalleryItem media={m} />
            </SwiperSlide>
          )
        })}
      </Swiper>

      {media.length > 1 && (
        <Swiper
          spaceBetween={16}
          slidesPerView={4}
          onSwiper={setThumbnailsSwiper}
          className="mt-4 rounded-md py-[1px] lg:mt-8 lg:px-4"
          {...props}
        >
          {media?.map((m, index) => {
            return (
              <SwiperSlide key={index}>
                <LaunchMediaGalleryThumbnail
                  media={m}
                  isActive={previewActiveIndex === index}
                  onClick={() => handleThumbnailClick(index)}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </div>
  )
}
LaunchMediaGallery.displayName = "LaunchMediaGallery"

const launchMediaGalleryItemStyle = "aspect-square overflow-hidden rounded-md"

type LaunchMediaGalleryItemProps = {
  media: MediaType
} & React.ComponentPropsWithRef<"div">

const LaunchMediaGalleryItem = ({
  media,
  className,
  ...props
}: LaunchMediaGalleryItemProps) => {
  return (
    <div
      className={cn(launchMediaGalleryItemStyle, "group", className)}
      {...props}
    >
      <Media
        media={media}
        size={MediaSize.Lg}
        className="h-full w-full bg-accent transition-transform duration-500 group-hover:scale-[1.025]"
      />

      <span className="sr-only">{media.alt}</span>
    </div>
  )
}
LaunchMediaGalleryItem.displayName = "LaunchMediaGalleryItem"

type LaunchMediaGalleryThumbnailProps =
  React.ComponentPropsWithRef<"button"> & {
    media: MediaType
    isActive?: boolean
  }

const LaunchMediaGalleryThumbnail = ({
  media,
  isActive,
  ...props
}: LaunchMediaGalleryThumbnailProps) => {
  return (
    <button
      className={cn(
        "group inline-flex aspect-square w-full transform select-none items-center justify-center overflow-hidden rounded-md border border-transparent ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
        {
          "border-foreground": isActive,
        }
      )}
      {...props}
    >
      <Media
        media={media}
        size={MediaSize.Sm}
        className="group bg-accent transition-all duration-300 group-hover:scale-105 group-hover:opacity-80"
      />
    </button>
  )
}
LaunchMediaGalleryThumbnail.displayName = "LaunchMediaGalleryThumbnail"

export {
  LaunchMediaGallery,
  launchMediaGalleryStyle,
  LaunchMediaGalleryItem,
  launchMediaGalleryItemStyle,
}
