import React from "react"
import {
  IconBrandDiscord,
  IconBrandTelegram,
  IconBrandTwitter,
  IconBrandX,
  IconExternalLink,
  IconMail,
} from "@tabler/icons-react"

import { ExternalLink, ExternalLinkType } from "@/types/external-link"

import { cn } from "@/utils/tw-utils"
import { Button, ButtonProps } from "@/components/ui/button"

const externalLinkIconByType: Record<ExternalLinkType, React.ElementType> = {
  [ExternalLinkType.Twitter]: IconBrandTwitter,
  [ExternalLinkType.X]: IconBrandX,
  [ExternalLinkType.Discord]: IconBrandDiscord,
  [ExternalLinkType.Telegram]: IconBrandTelegram,
  [ExternalLinkType.Website]: IconExternalLink,
  [ExternalLinkType.Contract]: IconExternalLink,
  [ExternalLinkType.Email]: IconMail,
  [ExternalLinkType.Other]: IconExternalLink,
}

type ExternalLinksProps = {
  links: ExternalLink[]
  variant?: "only-icons" | "only-text" | "icons-and-text"
  _link?: ButtonProps
} & React.ComponentPropsWithoutRef<"div">

const ExternalLinks = ({
  links,
  variant = "icons-and-text",
  className,
  _link = {},
  ...props
}: ExternalLinksProps) => {
  const showIcon = variant === "only-icons" || variant === "icons-and-text"
  const showText = variant === "only-text" || variant === "icons-and-text"

  const { className: linkClassName, ...linkProps } = _link

  return (
    <div className={cn("flex flex-wrap gap-x-3 gap-y-2", className)} {...props}>
      {links.map((link, index) => {
        const Icon = externalLinkIconByType[link.type]

        return (
          <Button
            key={index}
            variant="outline"
            className={cn("gap-2 font-normal", linkClassName)}
            size={variant === "only-icons" ? "icon" : undefined}
            asChild
            {...linkProps}
          >
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {showIcon && <Icon className="h-4 w-4" />}

              {showText ? (
                link.title
              ) : (
                <span className="sr-only">{link.title}</span>
              )}
            </a>
          </Button>
        )
      })}
    </div>
  )
}
ExternalLinks.displayName = "ExternalLinks"

export { ExternalLinks, externalLinkIconByType }
