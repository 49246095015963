import React, { useState } from "react"
import {
  AccordionItemProps,
  AccordionSingleProps,
} from "@radix-ui/react-accordion"
import {
  GemIcon,
  HelpCircleIcon,
  InfoIcon,
  LucideIcon,
  RocketIcon,
  ShieldIcon,
  UserIcon,
} from "lucide-react"

import {
  FaqCategory,
  FaqEntry as FaqEntryType,
  Faq as FaqType,
} from "@/types/faq"

import { cn } from "@/utils/tw-utils"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { StyledHtml } from "@/components/ui/styled-html"
import { VenomIcon } from "@/components/icons/venom-icon"

const faqCategoryIcons: Record<FaqCategory, LucideIcon> = {
  [FaqCategory.General]: InfoIcon,
  [FaqCategory.Profile]: UserIcon,
  [FaqCategory.Help]: HelpCircleIcon,
  [FaqCategory.Launchpad]: RocketIcon,
  [FaqCategory.Rewards]: GemIcon,
  [FaqCategory.Safety]: ShieldIcon,
  [FaqCategory.Venom]: VenomIcon,
}

type FaqProps = React.ComponentPropsWithRef<"div"> & {
  faq: FaqType
}

const Faq = React.forwardRef<HTMLDivElement, FaqProps>(
  ({ faq, ...props }: FaqProps, ref) => {
    const Icon = faqCategoryIcons[faq.category]
    const hasDescription = Boolean(faq.description)

    return (
      <section ref={ref} {...props}>
        <div className="flex items-center gap-3 md:gap-6">
          <div className="flex h-12 w-12 items-center justify-center overflow-hidden rounded-full bg-frozen/50 text-primary backdrop-blur md:h-14 md:w-14">
            <Icon className="h-5 w-5 md:h-6 md:w-6" />
          </div>

          <div>
            <h2 className="text-lg font-bold md:text-xl">{faq.title}</h2>
            {hasDescription && <p className="mt-1">{faq.description}</p>}
          </div>
        </div>

        <FaqEntries entries={faq.entries} className="mt-4 md:mt-8" />
      </section>
    )
  }
)
Faq.displayName = "Faq"

type FaqEntriesProps = Omit<AccordionSingleProps, "children" | "type"> & {
  entries: FaqEntryType[]
}

const FaqEntries = React.forwardRef<HTMLDivElement, FaqEntriesProps>(
  ({ entries, className, ...props }: FaqEntriesProps, ref) => {
    const [selectedQuestion, setSelectedQuestion] = useState<string>()

    return (
      <Accordion
        type="single"
        value={selectedQuestion}
        collapsible
        onValueChange={setSelectedQuestion}
        className={cn("flex flex-col md:gap-3", className)}
        ref={ref}
        {...props}
      >
        {entries.map((faqEntry, index) => {
          return (
            <FaqEntry
              key={index}
              value={index.toString()}
              faqEntry={faqEntry}
            />
          )
        })}
      </Accordion>
    )
  }
)
FaqEntries.displayName = "Faq"

type FaqEntryProps = AccordionItemProps & {
  faqEntry: FaqEntryType
}

const FaqEntry = ({ faqEntry, className, ...props }: FaqEntryProps) => {
  const { question, answer } = faqEntry

  return (
    <AccordionItem
      className={cn("md:rounded-md md:border", className)}
      {...props}
    >
      <AccordionTrigger className="py-4 text-left md:rounded-md md:p-6">
        <span className="text-lg font-bold">{question}</span>
      </AccordionTrigger>

      <AccordionContent className="md:px-6">
        <StyledHtml
          html={answer}
          className="max-w-2xl pb-6 text-base leading-relaxed"
        />
      </AccordionContent>
    </AccordionItem>
  )
}
FaqEntry.displayName = "FaqEntry"

export { Faq, FaqEntries }
