export interface LevelConfig {
  title: string
  description: string
  index: number
  minRewards: number
  maxRewards: number
  image: string
  chanceBoost?: number
}

export const appConfig = {
  useMocks: process.env.REACT_APP_USE_MOCKS === "true",
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  titleTemplate: "{{pageTitle}} | Dragonz Land",
} as const
