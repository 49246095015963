import React from "react"
import {
  IconBrandDiscord,
  IconBrandTelegram,
  IconBrandTwitter,
  IconBrandX,
  IconExternalLink,
  IconMail,
} from "@tabler/icons-react"

import { ExternalLink, ExternalLinkType } from "@/types/external-link"

import { cn } from "@/utils/tw-utils"
import { Button, ButtonProps } from "@/components/ui/button"

const externalLinkIconByType: Record<ExternalLinkType, React.ElementType> = {
  [ExternalLinkType.Twitter]: IconBrandTwitter,
  [ExternalLinkType.X]: IconBrandX,
  [ExternalLinkType.Discord]: IconBrandDiscord,
  [ExternalLinkType.Telegram]: IconBrandTelegram,
  [ExternalLinkType.Website]: IconExternalLink,
  [ExternalLinkType.Contract]: IconExternalLink,
  [ExternalLinkType.Email]: IconMail,
  [ExternalLinkType.Other]: IconExternalLink,
}

type ExternalLinksProps = {
  links: ExternalLink[]
  variant?: "only-icons" | "only-text" | "icons-and-text"
  _link?: ButtonProps
} & React.ComponentPropsWithoutRef<"div">

const ExternalLinksDialog = ({
  links,
  variant = "icons-and-text",
  className,
  _link = {},
  ...props
}: ExternalLinksProps) => {
  const { className: linkClassName, ...linkProps } = _link

  return (
    <div className={cn("flex flex-wrap", className)} {...props}>
      {links.map((link, index) => {
        const Icon = externalLinkIconByType[link.type]

        return (
          <div className="flex w-full md:w-1/2 flex-wrap p-4">
            <div className="block w-full">
              <h4 className="text-2xl text-white text-center">{link.type === ExternalLinkType.X ? "Follow on" : "Join"} {link.title}</h4>
              <p className="text-center"><a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a></p>
              <div className="pt-2 text-center">
                <Button
                  key={index}
                  variant="outline"
                  className={cn("gap-2 font-normal", linkClassName)}
                  size={variant === "only-icons" ? "icon" : undefined}
                  asChild
                  {...linkProps}
                >
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    <Icon className="h-4 w-4" />
                    {link.title}
                    <span className="sr-only">{link.title}</span>
                  </a>
                </Button>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
ExternalLinksDialog.displayName = "ExternalLinksDialog"

export { ExternalLinksDialog, externalLinkIconByType }
