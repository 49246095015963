import { cn } from "@/utils/tw-utils"
import { Connect } from "@/components/layout/connect"

const Header = () => {
  return (
    <header className="container relative z-10 my-1 flex h-16 w-full items-center justify-between px-3 md:px-6 lg:my-3">
      <div className="flex w-full items-center">
        <a
          href="https://www.dragonz.land/"
          className={cn(
            "ml-3 mr-6 flex h-10 w-[10rem] flex-none items-center rounded-md outline-none md:ml-6 lg:ml-0"
          )}
        >
          <span className="sr-only">Home</span>
          <img src="/dragonz-logo.svg" alt="logo" className="w-32" />
        </a>
      </div>

      <div className="flex h-full items-center gap-3">
        <Connect />
      </div>
    </header>
  )
}

Header.displayName = "Header"

export { Header }
