import { Outlet } from "react-router-dom"

import { LayoutProvider } from "@/providers/layout-provider"
import { ScrollToTop } from "@/components/ui/scroll-to-top"
import { Toaster } from "@/components/ui/toaster"
import { Header } from "@/components/layout/header"

const RootLayout = () => {
  return (
    <LayoutProvider>
      <Header />

      <main className="pb-24">
        <Outlet />
      </main>

      <ScrollToTop />
      <Toaster />
    </LayoutProvider>
  )
}
RootLayout.displayName = "RootLayout"

export { RootLayout }
