export enum ExternalLinkType {
  Twitter = "twitter",
  X = "x",
  Discord = "discord",
  Telegram = "telegram",
  Website = "website",
  Email = "email",
  Contract = "contract",
  Other = "other",
}

export interface ExternalLink {
  url: string
  title: string
  type: ExternalLinkType
}
